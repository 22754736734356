import React from 'react';
import { Image, PropsWithClassProps } from '@vgn-medien-holding/vgn-fe-components';
import { twMerge } from 'tailwind-merge';
import { FairuAsset } from '@src/lib/graphql/generated';

export interface AvatarProps extends PropsWithClassProps<'avatar'> {
  avatar: Partial<FairuAsset>;
  width?: string;
  height?: string;
  alt?: string;
}

export const Avatar = ({ avatar, alt, classProps }: AvatarProps) => {
  const containerStyle = twMerge('relative w-12 h-12 rounded-full overflow-hidden', classProps?.root);
  return (
    <div className={containerStyle}>
      {avatar?.url && (
        <Image
          src={avatar.url}
          alt={alt || avatar.alt || ''}
          copyright=""
          fill
          classProps={{ container: 'rounded-full', root: 'object-cover' }}
          sizes="180px"
        />
      )}
    </div>
  );
};
